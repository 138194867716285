<template>
  <div>
    <h2>Productos y servicios</h2>

    <section>
      <b-col lg="10" offset-lg="1">
        <b-row v-if="services.length > 0">
          <b-col md="4"  v-for="service in services" v-bind:key="service.id">
              <b-link href="#" @click.prevent="visitServiceDetail(service.id)">
                  <b-card
                    tag="article"
                    style="height: 26rem;"
                    class="mb-2"
                    rounded>
                      <b-carousel 
                        :id="service.id"
                        style="text-shadow: 1px 1px 2px #333; margin:auto"
                        controls
                        indicators
                        background="#ababab"
                        :interval="5000"
                        img-width="1024"
                        img-height="480"
                        v-model="slide"
                        @sliding-start="onSlideStart"
                        @sliding-end="onSlideEnd"
                        class="carousel-design">
                        <b-carousel-slide
                          :img-src="url"
                          v-for="url in service.photos"
                          v-bind:key="url">
                        </b-carousel-slide>
                      </b-carousel>                                             
                      <div class="card-img-overlay">
                        <div>
                          <b-badge class="md-1" pill variant="danger" >{{service.type == 1 ? 'Servicio': 'Producto'}}</b-badge>                            
                        </div>
                        </div>                      
                      <b-row>
                        <b-col md="12">
                          <div class="link-title text2">
                            <span class="service_name">
                              <strong>{{service.name}}</strong>
                            </span>                 
                          </div>                           
                          <hr/>
                        </b-col>
                      </b-row>                      
                      
                      <b-row>
                        <b-col md="12">
                          <div class="link-text">                            
                            <!-- <strong>Precio: </strong>                             -->                            
                            <h5>
                                <CIcon name="cilMoney" size="sm" height="48" alt="Logo" />
                                <span v-if="service.id == 1">
                                  <small> Desde</small>
                                </span>
                                <span v-if="userCurrency == 'COP'">                                                              
                                    {{ service.currencyCop | thousand }} COP
                                </span>                          
                                <span v-if="userCurrency == 'PEN'">
                                    {{ service.currencyPen | thousand }} PEN
                                </span>                             
                            </h5>                                                                                  
                          </div>                          
                        </b-col>
                        <b-col md="12">
                          <div class="link-text" v-if="service.durationHours != null ">
                            <CIcon name="cilClock" size="sm" height="48" alt="Logo" /> {{service.durationHours}}  {{service.durationHours == 1 ? ' hora': ' horas'}}                            
                          </div>
                          <div class="link-text" v-if="service.durationMinutes != null ">
                            <CIcon name="cilClock" size="sm" height="48" alt="Logo" /> {{service.durationMinutes}} minutos
                          </div>
                        </b-col>
                        <b-col md="12" v-if="service.type==1">
                          <div class="link-text">
                            <CIcon name="cilLocationPin" size="sm" height="48" alt="Logo" />                            
                            Popayán
                          </div>                          
                        </b-col>
                        <b-col md="12" v-else>
                          <div class="link-text">
                            Envíos a todo Colombia
                            <CIcon name="cifCo" size="sm" height="48" alt="Logo" />                            
                          </div>                          
                        </b-col>
                        <b-col lg="12" v-if="service.giveaway">
                          <b-badge class="md-1" pill variant="success" >Gratis 1er servicio</b-badge>               
                        </b-col>                        
                      </b-row>                                          
                  </b-card>
              </b-link>
          </b-col>
        </b-row>       
      </b-col>   
    </section>
  </div>
</template>

<script>
import services from '../../utils/services.js';
export default {
  name: "index",
  data () {
    return {
      services: [],
      userCurrency: 'COP',
      slide: 0,
      sliding: null,
    }
  },
  mounted(){
    this.services = services
    console.log(this.services)
  },
  methods: {
    onSlideStart (slide) {
      this.sliding = true
    },
    onSlideEnd: function (slide) {
      this.sliding = false
    },
    visitServiceDetail: function(serviceId){
      this.$router.push('/services/profile/' + serviceId)
    },
  }
}
</script>

<style scoped>

</style>